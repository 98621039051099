import React, { useEffect } from 'react';
import { useSession } from '../contexts/useSession';
import { useParams } from 'react-router-dom';

const Redirect = () => {
  const { supabase } = useSession();
  const { name } = useParams();

  useEffect(() => {
    const getLink = async () => {
      const { data, error } = await supabase
        .from('links')
        .select('*')
        .eq('link', name)
        .single();

      if (error) {
        console.log(error);
        return;
      }

      if (data) {
        window.location.href = data.url;
      }
    };

    getLink();
  }, []);

  return <div>Redirecting...</div>;
};

export default Redirect;
