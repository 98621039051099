import { AuthSession, SupabaseClient } from '@supabase/supabase-js';
import React, { createContext, useContext, useState } from 'react';

interface SessionContextProps {
  session: AuthSession;
  supabase: SupabaseClient;
}

const SessionContext = createContext<SessionContextProps>({
  session: null,
  supabase: null,
});

export const useSession = () => useContext(SessionContext);

export const SessionProvider: React.FC<{
  children: React.ReactNode;
  session: AuthSession;
  supabase: SupabaseClient;
}> = ({ children, session: sessionProp, supabase: supabaseProp }) => {
  const [session] = useState(sessionProp);
  const [supabase] = useState(supabaseProp);

  return (
    <SessionContext.Provider value={{ session, supabase }}>
      {children}
    </SessionContext.Provider>
  );
};
