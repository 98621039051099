import { useEffect, useState } from 'react';
import { useSession } from '../contexts/useSession';
import { LinkIcon, PencilIcon } from '@heroicons/react/24/solid';
import { DateTime } from 'luxon';
import EditLink from './editLink';

export const RecentlyCreatedLinks = ({}) => {
  const [links, setLinks] = useState([]);
  const [editingLink, setEditingLink] = useState();
  const { supabase } = useSession();

  const fetchData = async () => {
    const { data, error } = await supabase
      .from('links')
      .select(
        `
        id,
        link,
        url,
        privacy,
        description,
        profiles (name, email),
        created_at
      `,
      )
      .order('created_at', { ascending: false })
      .limit(15);

    console.log(data);
    console.log(error);
    setLinks(data);
  };

  useEffect(() => {
    fetchData();

    supabase
      .channel('links')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'links',
        },
        fetchData,
      )
      .subscribe();
  }, []);

  const openLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <EditLink
        link={editingLink}
        open={editingLink !== undefined}
        didCancel={() => setEditingLink(undefined)}
      />
      <div className="mt-4">
        <h1 className="font-bold py-4 border-b border-b-gray-200">
          Recently created links
        </h1>
      </div>

      <ul role="list" className="divide-y divide-gray-100">
        {links &&
          links.map((link) => (
            <li
              key={link.id}
              className="flex group justify-between gap-x-6 py-5 rounded">
              <div className="hidden shrink-0 sm:flex sm:flex-col">
                <div className="flex">
                  <p
                    onClick={() => openLink(link.url)}
                    className="text-2xl mb-1 font-bold text-green-600 flex hover:underline hover:cursor-pointer">
                    go/{link.link}
                  </p>
                  <LinkIcon
                    onClick={() => openLink(link.url)}
                    className="group-hover:block hidden h-5 w-5 mt-2 mx-1 ml-2 hover:cursor-pointer hover:bg-gray-200 p-1 rounded-md"
                  />
                  <PencilIcon
                    onClick={() => setEditingLink(link)}
                    className="group-hover:block hidden font-bold h-5 w-5 mt-2 mx-1 hover:cursor-pointer hover:bg-gray-200 p-1 rounded-md"
                  />
                </div>
                <p className="mt-1 text-lg font-semibold leading-5 text-gray-700">
                  {link.description && link.description}
                </p>

                <p className="mt-1 text-gray-400">
                  {new URL(link.url).hostname.replace('www.', '')}
                </p>
              </div>
              <div className="flex min-w-0 gap-x-4 text-end">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold text-gray-900">
                    {link.profiles && link.profiles.name}
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    {link.privacy}
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    Created{' '}
                    <time>
                      {DateTime.fromISO(link.created_at).toRelativeCalendar()}
                    </time>
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};
