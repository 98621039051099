import React from 'react';
import { useSession } from '../contexts/useSession';
import Layout from '../components/layout/Layout';
import CreateLink from '../components/createLink';
import { RecentlyCreatedLinks } from '../components/recentlyCreatedLinks';

const Dashboard = () => {
  const { session } = useSession();

  return (
    <Layout>
      <CreateLink />
      <RecentlyCreatedLinks />
    </Layout>
  );
};

export default Dashboard;
