import { useState } from 'react';
import { useSession } from '../contexts/useSession';
import LinkForm from './linkForm';

export default function CreateLink() {
  const { supabase, session } = useSession();
  const [loading, setLoading] = useState(false);

  const formSubmitted = async (values) => {
    setLoading(true);
    const { error } = await supabase.from('links').insert({
      ...values,
      profiles_id: session.user.id,
    });

    setLoading(false);
    if (error) {
      return {
        submissionSuccess: false,
        error: error.message,
      };
    }

    return {
      submissionSuccess: true,
    };
  };

  return (
    <div className="relative">
      <div>
        <h1 className="font-medium pb-2">New Link</h1>
      </div>
      <LinkForm disabled={loading} submitForm={formSubmitted} />
    </div>
  );
}
