import { Fragment, useState } from 'react';
import { useSession } from '../contexts/useSession';
import { Dialog, Transition } from '@headlessui/react';
import LinkForm from './linkForm';

export default function EditLink({ link, open, didCancel }) {
  const { supabase, session } = useSession();
  const [loading, setLoading] = useState(false);

  const formSubmitted = async (values) => {
    setLoading(true);
    const updateValues = {
      ...values,
      profiles_id: session.user.id,
    };

    const { error } = await supabase
      .from('links')
      .update(updateValues)
      .eq('id', link.id);

    console.log(error);
    if (error) {
      setLoading(false);
      return {
        submissionSuccess: false,
        error: error.message,
      };
    }

    setLoading(false);
    didCancel();
    return {
      submissionSuccess: true,
    };
  };

  const deleteConfirmed = async () => {
    const { error } = await supabase.from('links').delete().eq('id', link.id);

    if (!error) {
      didCancel();
    }
  };

  return (
    <div className="relative">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={didCancel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="w-1/2 h-96 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-0 text-left shadow-xl transition-all sm:my-8 sm:p-6 sm:pt-3">
                  <h2 className="font-semibold mt-2 mb-2">Edit Link</h2>
                  <LinkForm
                    deleteConfirmed={deleteConfirmed}
                    link={link}
                    disabled={loading}
                    submitForm={formSubmitted}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
