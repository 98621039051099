import { useEffect, useState } from 'react';
import {
  GlobeAsiaAustraliaIcon,
  LockClosedIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

const LinkForm = ({
  submitForm,
  deleteConfirmed,
  link,
  disabled,
}: {
  submitForm: (any) => any;
  deleteConfirmed?: () => any;
  link?: any;
  disabled?: boolean;
}) => {
  const [selectedType, setSelectedType] = useState<
    'Internal' | 'Private' | 'Public'
  >('Internal');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [description, setDescription] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (!link) return;
    setName(link.link);
    setUrl(link.url);
    setDescription(link.description);
    setSelectedType(link.privacy);
  }, [link]);

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter') {
      createLinkClicked();
    }
  };

  const createLinkClicked = async () => {
    setNameError('');
    setUrlError('');

    // Validate
    if (name === '') {
      setNameError('You need a unique name!');
      return;
    }

    if (/[\/.,;=+ ]/.test(name)) {
      setNameError('Name cannot contain /.,;=+ or spaces.');
      return;
    }

    if (url === '') {
      setUrlError('You need a url to go to!');
      return;
    }

    let formattedURL = url;
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      formattedURL = 'https://' + formattedURL;
    }

    if (!isValidUrl(formattedURL)) {
      setUrlError('Must be a valid URL!');
      return;
    }

    const values = {
      link: name.toLowerCase(),
      url: formattedURL,
      description,
      privacy: selectedType,
    };

    console.log(values);

    const submissionResponse = await submitForm(values);

    if (submissionResponse.submissionSuccess === true) {
      console.log('resetting');
      setName('');
      setUrl('');
      setDescription('');
    } else {
      if (
        submissionResponse.error &&
        submissionResponse.error.includes(
          'duplicate key value violates unique constraint',
        )
      ) {
        setNameError('A link with that name already exists!');
      } else {
        setNameError(submissionResponse.error);
      }
    }
  };

  return (
    <>
      <div className="overflow-hidden rounded-md border border-gray-300 shadow-sm focus-within:border-green-500 focus-within:ring-1 focus-within:ring-green-500">
        <label htmlFor="title" className="sr-only">
          New Link
        </label>
        <div className="flex rounded">
          <div className="text-lg w-20 text-center bg-gray-100 border-gray-300 shadown-sm border-r border-b border-r-solid h-full font-medium py-3 p-4 text-gray-600">
            link/
          </div>
          <input
            type="text"
            name="name"
            id="name"
            onKeyDown={handleKeyPress}
            autoComplete="off"
            className="disabled:bg-gray-100 bg-none w-full border-0 border-b border-gray-300 pl-2 pt-2.5 text-lg font-medium placeholder:text-gray-400"
            placeholder="newlink"
            value={name}
            disabled={disabled}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex bg-gray-100 rounded border-b-2 border-gray-200 border-solid">
          <div className="text-lg w-20 text-center bg-gray-100 border-gray-300 shadown-sm border-r border-r-solid h-full font-medium py-3 p-4 text-gray-600">
            URL
          </div>
          <input
            type="text"
            name="url"
            id="url"
            onKeyDown={handleKeyPress}
            className="disabled:bg-gray-100 bg-none w-full border-0 pl-2 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
            placeholder="https://kubecost.com"
            value={url}
            disabled={disabled}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <label htmlFor="description" className="sr-only">
          Description
        </label>
        <textarea
          rows={2}
          name="description"
          onKeyDown={handleKeyPress}
          id="description"
          className="block w-full resize-none border-0 py-0 pt-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Write a description..."
          defaultValue={''}
          value={description}
          disabled={disabled}
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-9" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-px bottom-0">
        <div className="flex flex-nowrap justify-end space-x-2 px-2 py-4 sm:px-7">
          <button
            onClick={() => setSelectedType('Private')}
            disabled={disabled}
            className={
              (selectedType === 'Private'
                ? 'bg-green-500 text-white'
                : 'text-gray-500 bg-gray-50 hover:bg-gray-100') +
              ` disabled:bg-gray-50 disabled:text-gray-500 relative inline-flex items-center whitespace-nowrap rounded-full px-2 py-2 text-sm font-medium sm:px-3`
            }>
            <LockClosedIcon
              className={
                (selectedType === 'Private' ? 'text-white' : 'text-gray-500') +
                ` h-3 w-3 lex-shrink-0 sm:-ml-1 mr-1 `
              }
            />
            Private
          </button>
          <button
            onClick={() => setSelectedType('Internal')}
            disabled={disabled}
            className={
              (selectedType === 'Internal'
                ? 'bg-green-500 text-white'
                : 'text-gray-500 bg-gray-50 hover:bg-gray-100') +
              ` disabled:bg-gray-50 disabled:text-gray-500 relative inline-flex items-center whitespace-nowrap rounded-full px-2 py-2 text-sm font-medium sm:px-3`
            }>
            <UsersIcon
              className={
                (selectedType === 'Internal' ? 'text-white' : 'text-gray-500') +
                ` h-3 w-3 lex-shrink-0 sm:-ml-1 mr-1 `
              }
            />
            Internal
          </button>
          <button
            onClick={() => setSelectedType('Public')}
            disabled={disabled}
            className={
              (selectedType === 'Public'
                ? 'bg-green-500 text-white'
                : 'text-gray-500 bg-gray-50 hover:bg-gray-100') +
              ` disabled:bg-gray-50 disabled:text-gray-500 relative inline-flex items-center whitespace-nowrap rounded-full px-2 py-2 text-sm font-medium sm:px-3`
            }>
            <GlobeAsiaAustraliaIcon
              className={
                (selectedType === 'Public' ? 'text-white' : 'text-gray-500') +
                ` h-3 w-3 lex-shrink-0 sm:-ml-1 mr-1 `
              }
            />
            Public
          </button>
        </div>
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex">
            {nameError !== '' && (
              <p className="text-sm text-red-500">{nameError}</p>
            )}
            {urlError !== '' && (
              <p className="text-sm text-red-500">{urlError}</p>
            )}
            {name !== '' && nameError === '' && urlError === '' && (
              <p className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400">
                <GlobeAsiaAustraliaIcon
                  className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                <span className="text-sm italic text-gray-500 group-hover:text-gray-600">
                  Web URL: <b>https://teevans.co/{name}</b>
                </span>
              </p>
            )}
          </div>
          <div className="flex-shrink-0 gap-2 flex">
            {showDeleteConfirm && (
              <>
                <button
                  disabled={disabled}
                  onClick={() => {
                    deleteConfirmed();
                    setShowDeleteConfirm(false);
                  }}
                  className="inline-flex items-center rounded-md bg-red-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                  Confirm
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setShowDeleteConfirm(false)}
                  className="inline-flex items-center rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                  Cancel
                </button>
              </>
            )}
            {deleteConfirmed && !showDeleteConfirm && (
              <button
                disabled={disabled}
                onClick={() => setShowDeleteConfirm(true)}
                className="inline-flex items-center rounded-md bg-red-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                Delete
              </button>
            )}
            {!showDeleteConfirm && (
              <button
                disabled={name === '' || url === '' || disabled}
                onClick={createLinkClicked}
                className="inline-flex items-center disabled:bg-gray-400 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                {link ? 'Update' : 'Create'}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkForm;
