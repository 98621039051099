import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import AppIndex from './pages';

const supabase = createClient(
  'https://vzbznmdezhohxzghuofj.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZ6YnpubWRlemhvaHh6Z2h1b2ZqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTgyNzAzNjgsImV4cCI6MjAxMzg0NjM2OH0.Rq6dlCvq67chuJ9rKjGzmdwyxeBfiPS0f-8VibH1xSk',
);

function App() {
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <Auth
        providers={[]}
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
      />
    );
  } else {
    return <AppIndex supabase={supabase} session={session} />;
  }
}

export default App;
