import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  redirect,
} from 'react-router-dom';
import Dashboard from './dashboard';
import { SessionProvider, useSession } from '../contexts/useSession';
import Redirect from './redirect';

interface Props {
  session: any;
  supabase: any;
}

const Logout = (supabase) => {
  if (supabase.supabase && supabase.supabase.auth) {
    supabase.supabase.auth.signOut();
  }
  return <Navigate to="/" replace={true} />;
};

const Loading = () => {
  return <div className="mt-16 text-center font-bold">Loading...</div>;
};

const AppIndex: React.FC<Props> = ({ session, supabase }) => {
  return (
    <SessionProvider supabase={supabase} session={session}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/app" />} />
          <Route path="/:name" element={<Redirect />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/app" element={<Dashboard />} />
          <Route path="/app/logout" element={<Logout supabase={supabase} />} />
        </Routes>
      </BrowserRouter>
    </SessionProvider>
  );
};

export default AppIndex;
